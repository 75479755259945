var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-0 pb-0"},[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.clients))])])],1),_c('v-card-text',{staticClass:"my-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items-per-page":5,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","items":_vm.clients.items},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.commercialName",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[(item.createdBy)?_c('v-chip',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$router.push('/clients/' + item.createdBy.id)}}},[_vm._v(" "+_vm._s(item.createdBy.email)+" ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}}])})],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }