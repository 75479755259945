var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-0 pb-0"},[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.orders))])])],1),_c('v-card-text',{staticClass:"my-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","items":_vm.orders.items},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.ref",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.company",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.client",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":"","outlined":""},on:{"click":function($event){return _vm.onClientClicked(item)}}},[_c('span',{staticClass:"grey--text text--darken-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.client.name)+" "+_vm._s(item.client.lastname)+" ")])])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[(item.createdBy)?_c('v-chip',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$router.push('/clients/' + item.createdBy.id)}}},[_vm._v(" "+_vm._s(item.createdBy.email)+" ")]):_vm._e()],1)]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(item.company.commercialName)+" ")])],1)]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.connectorId)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('OrderStatusComponent',{attrs:{"orderType":item.orderType}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}}])})],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }