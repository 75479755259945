





























































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import Address, { AddressType, AddressSelect } from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import AddressDialog from "@/components/dialog/AddressDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import HistoryDataService from "@/services/HistoryDataService";

@Component({ components: { } })
export default class ClientsHistoryTable extends Vue {

  lang: any = getModule(LangModule).lang
  dialog: boolean = false
  loading: boolean = false
  page = 1
  size = 7
  pageCount = 0
  clients: MultipleItem<Client> = { items: [], totalItems: 0 }

  headers = [
    { text: "ID", value: "id", width: "80", align: "center" },
    { text: this.lang.name, value: "name", width: "auto", align: "center" },
    { text: this.lang.commercialName, value: "commercialName", width: "auto", align: "center" },
    { text: this.lang.email, value: "email", width: "auto", align: "center" },
    { text: this.lang.createdBy, value: "createdBy", width: "auto", align: "center" },
    { text: this.lang.date, value: "createdAt", width: "auto", align: "center" }
  ]

  created() { this.refresh() }

  async refresh() {
    try {
      await Handler.getItems(this, this.clients, () =>
          HistoryDataService.findClients(this.page - 1,this.size, null)
      )
      this.pageCount = Math.ceil(this.clients.totalItems!! / this.size)
    } catch (e) { console.log(e) }

  }

  @Watch('page')
  onPageChanged() { this.refresh() }

}
