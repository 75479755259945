





































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import SessionModule from "@/store/SessionModule";
import ClientsHistoryTable from "@/components/tabs/historyData/ClientsHistoryTable.vue";
import CommercialHistoryTable from "@/components/tabs/historyData/CommercialHistoryTable.vue";
import OrderHistoryTable from "@/components/tabs/historyData/OrderHistoryTable.vue";

@Component( { components: {CommercialHistoryTable, ClientsHistoryTable, OrderHistoryTable } } )
export default class NewOrder extends Vue {
  @Ref() readonly form!: HTMLFormElement

  sessionModule: SessionModule = getModule(SessionModule)
  lang: any = getModule(LangModule).lang
  loading: boolean = false
  tab: number = 0

  historyDataTypes = [
    { name: this.lang.commercials, index: 0 },
    { name: this.lang.clients, index: 1 },
    { name: this.lang.orders, index: 2 },
  ]

}
